<template>
  <div class="global_theme_ui_page main_task_details_page_wrapper ">
    <!-- Start Patterns -->
    <UserPattern :userScore="userScore"></UserPattern>
    <!-- End Patterns -->
    <!-- Task Decoding -->
    <div class="decode_task_section">
      <div
        class="d-flex justify-content-between align-items-center mb-3 hide_element_while_printing"
      >
        <h2 class="main_title m-0">Task Decoding</h2>
        <div class="d-flex align-items-center" style="gap: 10px">
          <img src="@/assets/new-theme/edit-05.png" alt="edit" />
          <span class="primary_theme_color">
            Edit task description
          </span>
        </div>
      </div>
      <div class="wrap_decode">
        <div
          class="wrap_title d-flex align-items-center justify-content-between"
        >
          <div class="part_one">
            <img src="@/assets/new-theme/logo.png" alt="logo" />
            <span class="span_title">Task description</span>
            <span class="span_description">
              - Study Skills - Stress Management</span
            >
          </div>
          <div
            class="d-flex align-items-center hide_element_while_printing"
            style="gap: 10px"
          >
            <el-switch
              v-model="switchValue"
              active-color="#6941c6"
              inactive-color="#DCDFE6"
            >
            </el-switch>
            <span>
              Border style
            </span>
          </div>
        </div>
        <div
          class="for-task-description global_decode_content"
          :class="{ border_style: switchValue }"
          v-html="mainTaskData.description"
        ></div>
      </div>
    </div>

    <div class="mt-5 hide_element_while_printing">
      <SharePrintCard
        title="How to Use Suggested Strategies?"
        text="Suggested strategies are based on your Learning Patterns."
        @print="printPlp"
        @share="toggleModalShare()"
        class="hide_element_while_printing"
      ></SharePrintCard>
    </div>

    <!-- Use Your Learning Patterns -->
    <div class="learner_patterns_wrapper hide_element_while_printing">
      <div class="main_cards mt-3">
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="@/assets/new-theme/profile/check-2.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Select the perfect strategies
            </span>
            <p class="mb-0 description">
              Select strategies you feel might work for you under each of
              learning patterns and use it to complete your task.
            </p>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="@/assets/new-theme/profile/plus-back.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Add your own strategies
            </span>
            <p class="mb-0 description">
              Add your own personalized strategies under each one of the
              learning pattern easily.
            </p>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img class="img" src="@/assets/new-theme/heart.png" alt="" />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Use the selected strategies
            </span>
            <p class="mb-0 description">
              To successfully complete your task, use the strategies you select
              and print or share it with others.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Use Your Learning Patterns -->

    <!-- Start Collapse Items -->
    <div class="global_theme_ui_collapse main_profile_personalize_and_collapse">
      <el-collapse
        class="mt-3 customization_for_collapse"
        v-model="panel"
        multiple
      >
        <div
          class="main_child "
          v-for="(attribute, i) in userAttributes"
          :key="i"
          :style="`border : 2px solid ${getProgressColor(i)}`"
        >
          <el-collapse-item
            :name="`${i}`"
            class=""
            :class="{ break_section: i == 'Precision' }"
          >
            <template #title>
              <div class="custom_header_of_collapse">
                <img
                  src="@/assets/new-theme/attributes-images/sequence.png"
                  alt="sequence"
                  v-if="i == 'Sequence'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/precision.png"
                  alt="precision"
                  v-if="i == 'Precision'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/technical_reasoning.png"
                  alt="technical_reasoning"
                  v-if="i == 'Technical Reasoning'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/confluence.png"
                  alt="confluence"
                  v-if="i == 'Confluence'"
                />
                <span class="title_word">{{ i }} - {{ patternKey(i) }}</span>
                <span
                  class="selected_number"
                  v-if="
                    i == 'Sequence' &&
                      mainTaskData.selected_strategies_count.Sequence
                  "
                  >{{
                    mainTaskData.selected_strategies_count.Sequence
                  }}
                  strategy selected</span
                >
                <span
                  class="selected_number"
                  v-if="
                    i == 'Precision' &&
                      mainTaskData.selected_strategies_count.Precision
                  "
                  >{{
                    mainTaskData.selected_strategies_count.Precision
                  }}
                  strategy selected</span
                >
                <span
                  class="selected_number"
                  v-if="
                    i == 'Technical Reasoning' &&
                      mainTaskData.selected_strategies_count[
                        'Technical Reasoning'
                      ]
                  "
                  >{{
                    mainTaskData.selected_strategies_count[
                      "Technical Reasoning"
                    ]
                  }}
                  strategy selected</span
                >
                <span
                  class="selected_number"
                  v-if="
                    i == 'Confluence' &&
                      mainTaskData.selected_strategies_count.Confluence
                  "
                  >{{
                    mainTaskData.selected_strategies_count.Confluence
                  }}
                  strategy selected</span
                >
              </div>
            </template>

            <!-- Start Checkboxes -->
            <div class="p-3">
              <el-collapse class="collapse_checkboxes">
                <div
                  class="checkboxes_wrapper"
                  v-for="(item, index) in attribute"
                  :key="index"
                >
                  <el-checkbox-group class="" v-model="selected" v-if="item">
                    <el-checkbox
                      v-if="item"
                      :label="item.id"
                      @change="updateSelectedAttributes"
                      :class="{
                        'd-none': item.level1 < 1
                      }"
                    >
                      {{ item.level1 }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <audio controls id="audio" class="d-none">
                    <source id="audio-source" type="audio/mp3" />
                  </audio>
                  <span
                    class="span_img_wrapper hide_element_while_printing"
                    role="button"
                    @click="text_speech_audio(item.level1)"
                  >
                    <img
                      src="@/assets/new-theme/attributes-images/volume.png"
                      alt="volume"
                    />
                  </span>
                </div>
                <div class="your_own_statement hide_element_while_printing">
                  <img
                    role="button"
                    src="@/assets/new-theme/profile/plus.png"
                    alt="plus"
                  />
                  <span role="button">Add your own personalized statement</span>
                </div>
              </el-collapse>
            </div>
            <!-- End Checkboxes  -->
          </el-collapse-item>
        </div>
      </el-collapse>
    </div>

    <!-- Start Collapse Items -->

    <!--  Start Share -->
    <ShareModal
      :showHideModalShare="showHideModalShare"
      @close="toggleModalShare"
      @submit="toggleModalShare"
      title="Share your Work Task"
      description="Share your personalized work profile and learning patterns score with others to let them know how you learn, work, and interact with others."
    ></ShareModal>
    <!--  End Share -->
  </div>
</template>

<script>
export default {
  name: "WorkDetails",
  data() {
    return {
      showHideModalShare: false,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected: [],
      taskStrategies: [],
      mainTask: {},
      switchValue: false,
      showHideModalUserType: false
    };
  },
  computed: {
    mainUserId() {
      return this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userAttributes() {
      return this.taskStrategies;
      // return this.$store.getters["user/onetask"];
    },
    mainTaskData() {
      return this.mainTask;
      // return this.$store.getters["user/onetask"];
    },
    isAuthEqualUser() {
      return this.auth().id == this.mainUserId;
    },
    userData() {
      return this.$store.state.user.user;
    },
    taskId() {
      return this.$route.params.task_id;
    }
  },
  methods: {
    // Toggle Modal User Type
    toggleModalShare() {
      this.showHideModalShare = !this.showHideModalShare;
    },
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute
        // this.selected = this.userData.selected_attributes;
      });
    },
    printPlp() {
      window.print();
    },
    // Toggle Modal User Type
    toggleModalShare() {
      this.showHideModalShare = !this.showHideModalShare;
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    // Return pattern key in collapse
    patternKey(name) {
      if (name == "Sequence") {
        // Start Pattern range
        if (this.userScore?.sequence >= 25 && this.userScore.sequence <= 35) {
          return "Use First";
        } else if (
          this.userScore?.sequence >= 7 &&
          this.userScore.sequence <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.sequence >= 18 &&
          this.userScore.sequence <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Precision") {
        // Start Pattern range
        if (this.userScore?.precision >= 25 && this.userScore.precision <= 35) {
          return "Use First";
        } else if (
          this.userScore?.precision >= 7 &&
          this.userScore.precision <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.precision >= 18 &&
          this.userScore.precision <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Technical Reasoning") {
        // Start Pattern range
        if (
          this.userScore?.technical_reasoning >= 25 &&
          this.userScore.technical_reasoning <= 35
        ) {
          return "Use First";
        } else if (
          this.userScore?.technical_reasoning >= 7 &&
          this.userScore.technical_reasoning <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.technical_reasoning >= 18 &&
          this.userScore.technical_reasoning <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Confluence") {
        // Start Pattern range
        if (
          this.userScore?.confluence >= 25 &&
          this.userScore.confluence <= 35
        ) {
          return "Use First";
        } else if (
          this.userScore?.confluence >= 7 &&
          this.userScore.confluence <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.confluence >= 18 &&
          this.userScore.confluence <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
    },
    // Get task details
    refresh() {
      this.$store
        .dispatch("user/showAtWorkTask", { id: this.taskId })
        .then(res => {
          this.taskStrategies = res?.task?.strategy_id;
          // console.log(
          //   "🚀 ~ this.$store.dispatch ~ this.taskStrategies:",
          //   this.taskStrategies
          // );

          // console.log(
          //   "🚀 ~ refresh - before ~ this.userAttributes:",
          //   this.userAttributes
          // );

          // Object.keys(this.userAttributes).forEach(key => {
          //   console.log(`Key: ${key}, Value: ${this.userAttributes[key]}`);
          // });

          // Object.keys(this.userAttributes).forEach(key => {
          //   if (
          //     this.userAttributes[key] === true ||
          //     this.userAttributes[key] === false
          //   ) {
          //     this.userAttributes[key] = [];
          //   }
          // });

          console.log(
            "🚀 ~ refresh - after ~ this.userAttributes:",
            this.userAttributes
          );

          // console.log("from task details: ", res.task.strategy_id);
          this.mainTask = res?.task;

          this.selected =
            res?.task?.selected_strategy_id !== null
              ? res?.task?.selected_strategy_id
              : [];

          // this.$store.dispatch("user/user", this.user).then(res => {
          //   console.log("i am user here");
          // });
          // this.selected_panel = [];
          // this.strategies = this.task.selected_strategy_id
          //   ? this.task.selected_strategy_id
          //   : [];
          //  handling tasks tooltip
          let html_task_handler = document.querySelector(
            ".for-task-description"
          );

          const all_child_nodes = Array.from(
            html_task_handler.getElementsByTagName("div")
          );

          all_child_nodes.forEach(element => {
            let get_tooltip_data = element.getAttribute("data-tooltip");
            let create_span = document.createElement("span");
            create_span.textContent = get_tooltip_data;
            create_span.setAttribute("class", "span_for_tooltips");
            element.appendChild(create_span);
          });
        });
    },
    updateSelectedAttributes() {
      this.$store
        .dispatch("user/update_task", {
          id: this.taskId,
          query: { selected_strategy_id: this.selected }
        })
        .then(() => {
          this.getMainUserData();
          this.refresh();
        });
    },

    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    }
  },
  mounted() {
    this.getMainUserData();
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.main_task_details_page_wrapper {
  // Use Your Learning Patterns
  .learner_patterns_wrapper {
    margin-top: 40px;
    .titles_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .print_share_buttons {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .btn_2 {
      border: 1px solid transparent;
      border-radius: 8px;
      color: #6941c6;
      font-size: 14px;
      height: 41px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      gap: 10px;
      background: #fff;
    }
    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .main_cards {
      display: flex;
      justify-content: space-between;
      gap: 4%;
      .card_item {
        border-radius: 8px;
        border: 1px solid #eaecf0;
        padding: 24px;
        width: calc(96% / 3);

        .title {
          color: #101828;
          font-weight: 600;
          font-size: 18px;
        }
        .description {
          color: #667085;
          font-weight: 400;
          font-size: 16px;
        }
        .lci_btn {
          background-color: #f2f4f7;
          color: #6941c6;
          border-radius: 8px;
          padding: 12px 20px;
          border: 1px solid #eaecf0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }

  .main_title {
    color: #101828;
    font-weight: 600;
    font-size: 20px;
    &.white_text {
      color: #fff;
    }
  }
  .text {
    color: #667085;
    font-weight: 400;
    font-size: 16px;
  }
}
</style>

<style lang="scss">
.user_modal {
  padding: 0;
  .el-dialog__header {
    display: none !important;
  }
}

.main_profile_personalize_and_collapse {
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__content {
    box-shadow: none !important;
    border: none !important;

    // @media print {
    //   padding-bottom: 0;
    // }
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    font-size: 16px;
    line-height: inherit;
    color: #344054 !important;
  }
  .el-checkbox__inner {
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
  }
  .el-checkbox {
    align-items: flex-start;
    display: flex;
  }
  .el-checkbox__label,
  .el-checkbox is-checked {
    color: #344054 !important;
    font-weight: 400;
    font-size: 16px;

    // max-width: 98%;
    word-break: break-word !important;
    white-space: pre-wrap;
    // word-wrap: break-word;
    white-space: normal;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6941c6;
    border-color: #6941c6;
  }
  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    border-width: 2px;
  }
}
</style>
